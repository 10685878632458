$(window).load(function () {
	// Ordenação dos itens
	// console.log($('.listWithHandle'));
	if ($('.listWithHandle').length) {
		$('.listWithHandle').each(function(index) {
			var element = document.getElementById($(this).attr("id"));
			// console.log($(this).attr("id"));
			Sortable.create(element, {
				handle: '.glyphicon-move',
				animation: 150,
				onUpdate: function (evt/**Event*/){
					var item = evt.item; // the current dragged HTMLElement
					var controller = $(evt.to).attr("data-controller");
					var action = ($(evt.to).attr("data-action"))? $(evt.to).attr("data-action"):"order";
					var module = $(evt.to).attr("data-module");
					var dtOrder = $(evt.to).attr("data-order");
					var dtCount = $(evt.to).attr("data-count");
					var order = $(evt.to).find("input").serializeArray();

					$("#loading").show();
					$.post(URL_BASE+module+controller+"/"+action, {order:order}, function(theResponse){
						$("#loading").hide();

						if (dtOrder === "DESC") {
							$(evt.to).find("tr").each(function (key, elem) {
								$(this).find(".ln-order").html(dtCount);
								dtCount = dtCount - 1;
							});
						}else{
							$(evt.to).find("tr").each(function (key, elem) {
								$(this).find(".ln-order").html(key+1);
							});
						}
					});
				}
			});
		});
		
	}
});